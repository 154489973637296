import { RegisterStoreKeys, useRegisterStore } from '@/stores/register.store'
import { RouteLocationNormalized } from 'vue-router'

const isRoutingByApp = (hasPageName: boolean) => {
  return hasPageName
}

const isHasRequiredKeys = (requiredKeys?: RegisterStoreKeys[]) => {
  const registerStore = useRegisterStore()
  return (
    requiredKeys &&
    requiredKeys.length > 0 &&
    requiredKeys.every((key) => registerStore.hasValue(key))
  )
}

const isDontHaveKeys = (notRequiredKeys?: RegisterStoreKeys[]) => {
  const registerStore = useRegisterStore()
  return (
    !notRequiredKeys ||
    notRequiredKeys.length === 0 ||
    notRequiredKeys.every((key) => !registerStore.hasValue(key))
  )
}

export const GuardRouter =
  (requiredKeys?: RegisterStoreKeys[], notRequiredKeys?: RegisterStoreKeys[]) =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) => {
    if (
      isRoutingByApp(!!from.name) ||
      (isHasRequiredKeys(requiredKeys) && isDontHaveKeys(notRequiredKeys))
    ) {
      next()
    } else {
      next({ path: '/', query: to.query })
    }
  }
