import { createRouter, createWebHistory } from 'vue-router'
const MobileForm = () => import('@/pages/mobile-form/MobileForm.vue')
const Otp = () => import('@/pages/otp/Otp.vue')
const UserForm = () => import('@/pages/user-form/UserForm.vue')
const Address = () => import('@/pages/address/Address.vue')
const CompleteRegister = () =>
  import('@/pages/complete-register/CompleteRegister.vue')
const NonThaiLoading = () =>
  import('@/pages/nonthai-loading/NonThaiLoading.vue')
const TmnLoading = () => import('@/pages/tmn-loading/TmnLoading.vue')
const NotFound = () => import('@/pages/not-found/NotFound.vue')
import { GuardRouter } from './guard'

export default createRouter({
  history: createWebHistory(),
  routes: [
    { name: 'mobileForm', path: '/', component: MobileForm },
    {
      name: 'otp',
      path: '/otp',
      component: Otp,
      beforeEnter: GuardRouter(['phoneNumber', 'otpRefCode'], ['otpCode']),
    },
    { name: 'tmn', path: '/tmn', component: TmnLoading },
    { name: 'tmn-register', path: '/tmn-register', component: TmnLoading },
    { name: 'nonthai', path: '/nonthai', component: NonThaiLoading },
    {
      name: 'userForm',
      path: '/user-form',
      component: UserForm,
      beforeEnter: GuardRouter(['phoneNumber', 'otpRefCode', 'otpCode']),
    },
    { name: 'address', path: '/address', component: Address },
    {
      name: 'complete',
      path: '/complete',
      component: CompleteRegister,
      beforeEnter: GuardRouter(),
    },
    { name: 'notFound', path: '/:pathMatch(.*)*', component: NotFound },
  ],
})
